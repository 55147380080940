import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';

const swup = new Swup({
	plugins: [
		new SwupScrollPlugin({
			doScrollingRightAway: false,
			animateScroll: false
		})]
});

$(document).ready(function () {
	randomColor();
	colorModes()
	canvasAnimation();
	
	init();
	
	function init() {
		
		//General
		watchForHover()
		externalLinksInNewTab()
		colorModes()
		rotateFooterLine()
		initializeLightboxes()
		delayLoadingMultipliedImages()
		breakLongWordsMobile()
		subpageMobileNavigation()

		//Home
		homeSlideshow()
		homeSiteTitleWhite()
		// refreshHomeOnResize()
		
		//Menu
		triggerMenu() 

		//Meldungen
		openMeldungDetail()
		// meldungLightboxes()
		
		//Profil
		scrollToAnchor()
		profileMobileAccordeon()

		//Projekte
		filterProjects()
		filterMobileAccordeon()

		//Prokotolle
		protokollItemAccordeon()

		//Studio
		studioMobileAccordion()
		


		document.addEventListener('swup:contentReplaced', event => {
			colorModes()
			initializeLightboxes()
			subpageMobileNavigation()
			
			if ($("#home")[0]) {
				homeSiteTitleWhite()
				canvasAnimation()
			} else {
				slideshowIntervalTimer = false;
				clearInterval(slideInterval);
				clearTimeout(slideshowTimer);
			}

			event.stopImmediatePropagation()
		})

	}
	
	swup.on('contentReplaced', init);
	swup.on('pageView', init);
	


	var resizeTimer;

	var windowWidth = $(window).width();
	var windowHeight = $(window).height();

	$(window).resize(function () {

		clearTimeout(resizeTimer);

		resizeTimer = setTimeout(function () {

			subpageMobileNavigation()
			filterProjects()
			filterMobileAccordeon() 
			refreshHomeOnResize()
			breakLongWordsMobile()
			studioMobileAccordion()

			// if ($("#home")[0]) {
			// 	canvasAnimation()
			// }

		}, 500);

	})


});